import React, { useEffect, useState, useCallback } from 'react';
import { Switch, BrowserRouter, Router, useLocation, useHistory, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Route from './Route';
import SignIn from '../pages/Login/SignIn';
import SignUp from '../pages/Login/SignUp';
import Dashboard from '../pages/Dashboard';
import PlansList from '../pages/Plans/List';
import ClassesList from '../pages/Classes/List';

import AppointmentsList from '../pages/Appointments';
import UserRegistration from '../pages/User/Registration';

import PaymentChekout from '../pages/Payments/Checkout';
import PaymentChekoutSuccess from '../pages/Payments/Checkout/Success';

import Error404 from '../pages/Errors/404';

import { useAuth } from '../hooks/auth';
import { useToast } from '../hooks/toast';
import PaymentsPending from '../pages/Payments/Pending';
import { ThemeProvider, createMuiTheme, Theme, makeStyles } from '@material-ui/core/styles';
import Training from '../pages/Training';
import LandingPage from '../pages/LangindPage';
import ConvitePage from '../pages/Convite';
import LandingPageAgendamento from '../pages/LangingPageAgendamento';
import PaymentSaleConfirmation from '../pages/Payments/DebitConfirm/PaymentSale';
import CheckoutRedirect from '../pages/Payments/Checkout/RedirectAuth';
import PaymentRenovationConfirmation from '../pages/Payments/DebitConfirm/PaymentRenovation';
import PaymentPendingConfirmation from '../pages/Payments/DebitConfirm/PaymentPending';
import { Grid, CircularProgress } from '@material-ui/core';
import RecoveryPassword from '../pages/Login/RecoveryPassword';
import Forms from '../pages/Forms';
import CadastroAgendamento from '../pages/LangingPageAgendamento/CadastroAgendamento';
import LandingPageCalendario from '../pages/LangingPageAgendamento/CalendarioAgendamento';
import LandingPageCalendarioCadastro from '../pages/LangingPageAgendamento/CalendarioCadastro';
import CardsAdd from '../pages/Payments/Cards';
import CardsSuccess from '../pages/Payments/Cards/Success';




const useStyles = makeStyles((theme: Theme) => ({
  logoHubfit: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& img': {
      height: '65px',
    }
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  GridList: {
    margin: '50px 0 !important',
  }
}));



const Routes: React.FC = () => {
  const classes = useStyles();
  const { addToast } = useToast();
  let location = useLocation();
  const history = useHistory();
  const { tenant, signInTenant, signInTenantId } = useAuth();
  const [ready, setReady] = useState<boolean>(false);
  const [theme, setTheme] = useState<Theme>(createMuiTheme());

  useEffect(
    () => {

      const fetchData = async () => {
        try {
         

          const domain = location.pathname.split('/')[1];
          if (domain == "a" || domain == "recoverypassword" || domain == "cards") {
            const parms = queryString.parse(location.search);
            if (parms.idt) {
              await signInTenantId({
                idTenant: parms.idt.toString(),
              }).catch((error) => {
                console.log(error)
                addToast({
                  type: "error",
                  title: 'Erro',
                  description: error.message,
                  time: 20000
                });
              });

              //   const tenantLocal =localStorage.getItem('@HubfitPortal:tenant');
              //   if(tenantLocal){
              //     const tenantObj = JSON.parse(tenantLocal);
              //  //   location.pathname = tenantObj.domain+"/payments/pending";
              //     history.replace(tenantObj.domain+"/payments/pending")
              //   }

            }
          } else {
            if (domain) {


              const localStr = localStorage.getItem("@HubfitPortal:tenant");

              //Dados iguais, fazer nova busca para atualização
           //   if (window.location.href.indexOf("calendario") <= -1 && window.location.href.indexOf("agendamento") <= -1) {



                await signInTenant({
                  domain: location.pathname.split('/')[1],
                }).catch((error) => {
                  console.log(error)
                  addToast({
                    type: "error",
                    title: 'Erro',
                    description: error.message,
                    time: 20000
                  });
                  return;
                });
             // }

              if (localStr) {
                const tenantLocal = JSON.parse(localStr);
                console.log(domain, tenantLocal.domain)
                if (domain != tenantLocal.domain) {
                  sessionStorage.clear();
                  //window.location.reload(false);
                }
              }
              setReady(true);
            } else {

              // if (location.pathname != '/404') {
              //   history.replace('/404');
              // }
            }

          }
        } catch (err) {
          console.log('erro', err);

          history.replace('/404');
        } finally {

        }
        setReady(true);
      }
      fetchData();
    },
    [location.pathname]
  )


 

// Randomly change to showcase updates
setInterval(setContrast, 1000);

const hex2rgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  
  // return {r, g, b} 
  return [r, g, b];
}

function setContrast(hexa: string) {
 
if(hexa){
  const rgb = hex2rgb(hexa);
 
console.log(hexa, rgb);

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(((rgb[0] * 299) +
                      ((rgb[1]) * 587) +
                      ((rgb[2]) * 114)) / 1000);
                      console.log(brightness);
  const text =  (brightness > 125) ? 'black' : 'white';
console.log(text);
  return text;
}else{
  return "white";
} 
}


  useEffect(() => {

    if (tenant) {
console.log(tenant);
      const theme = createMuiTheme({
        palette: {
          primary: {
            main: `${tenant?.primaryColorTheme ? tenant?.primaryColorTheme : '#673AB7'}`,
          },
          secondary: {
            main: `${tenant?.secundaryColorTheme ? tenant?.secundaryColorTheme : '#512DA8'}`,
          },
        },
        overrides: {
          MuiButton: {
            containedPrimary: {
              color: `${tenant.primaryColorTheme ? setContrast(tenant.primaryColorTheme) : '#fff'}`,
            },
            containedSecondary: {
              color: `${tenant.secundaryColorTheme ? setContrast(tenant.secundaryColorTheme) : '#fff'}`,
            },
          },
          MuiAppBar: {
            colorPrimary: {
              color: `${tenant.primaryColorTheme ? setContrast(tenant.primaryColorTheme) : '#fff'}`,
            }
          }
        }
      });
      theme.typography.h3 = {
        fontSize: '1.9rem',
        '@media (min-width:600px)': {
          fontSize: '1.8rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '2rem',
        },
      }
      theme.typography.h4 = {
        fontSize: '1.0rem',
        '@media (min-width:600px)': {
          fontSize: '1.2rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.2rem',
        },
      }
      theme.typography.h6 = {
        fontSize: '1.0rem',
        '@media (min-width:600px)': {
          fontSize: '1.2rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '1.2rem',
        },
      }
      setTheme(theme);
    }
  }, [tenant?.primaryColorTheme, tenant?.secundaryColorTheme, tenant?.textColorTheme]);



  return (

    <>
      <ThemeProvider theme={theme}>

        {ready ? (

          <BrowserRouter basename={`/${tenant?.domain}`}>
            <Switch>

              <Route path="/" exact component={Dashboard} isPrivate />
              <Route path="/a" exact component={PaymentChekout} />
              <Route path="/cards" exact component={CardsAdd} />
              <Route path="/404" component={Error404} />
              <Route path="/login" component={SignIn} />
              <Route path="/signup" component={SignUp} />
              <Route path="/unidade/:id" component={SignIn} />
              <Route path="/recoverypassword" component={RecoveryPassword} />
              <Route path="/promo/:id" component={LandingPage} />
              <Route path="/convite/:id" component={ConvitePage} />
              <Route path="/agendamento/:id" exact component={LandingPageAgendamento} />
              <Route path="/agendamento/:id/calendario" exact component={LandingPageCalendario} />
              <Route path="/agendamento/:id/calendario/cadastro" exact component={LandingPageCalendarioCadastro} />
              <Route path="/agendamento/:id/cadastro" component={CadastroAgendamento} />
              <Route path="/forms/test/:idunidade" component={Forms} />
              <Route path="/forms/:id" component={Forms} />


              <Route path="/plans/list" component={PlansList} />
              <Route path="/compra-online" exact component={PlansList} />
              <Route path="/compra-online/:id" exact component={PlansList} />

              <Route path="/classes/list" component={ClassesList} />
              <Route path="/payments/cards" exact component={CardsAdd} />
              <Route path="/payments/cards/success" component={CardsSuccess} />
              <Route path="/payments/checkout" exact component={PaymentChekout} />
              <Route path="/payments/checkout/success" component={PaymentChekoutSuccess} />
              <Route path="/payments/pending" component={PaymentsPending} isPrivate />

              <Route path="/payments/checkout/redirectauth" component={CheckoutRedirect} />

              <Route path="/paymentsale/confirmation" component={PaymentSaleConfirmation} isPrivate />
              <Route path="/paymentpending/confirmation" component={PaymentPendingConfirmation} />
              <Route path="/paymentrenovation/confirmation" component={PaymentRenovationConfirmation} isPrivate />


              <Route path="/appointments" exact component={AppointmentsList} isPrivate />

              <Route path="/training" component={Training} isPrivate />

              <Route path="/user/registration" component={UserRegistration} isPrivate />
              <Route path="/" component={CheckoutRedirect} />
              {/* <Route path="/" component={SignIn} /> */}
              {/* <Route path="/" component={Error404} /> */}
            </Switch>
          </BrowserRouter>
        ) : (
          <Grid container direction="column"
            justify="center"
            alignItems="center" >
            <Grid item xs={12} className={classes.GridList}>
              <CircularProgress size={40} />
            </Grid>
          </Grid>
        )
        }
      </ThemeProvider>
    </>
  )
};

export default withRouter(Routes);
