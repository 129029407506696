import React, { useEffect, useState, useCallback, useRef } from 'react';


import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';
import { makeStyles, createStyles, Theme, Grid, CircularProgress, Paper, Typography, Button, Container, Dialog, IconButton, DialogTitle, DialogContent, FormControl, Select, MenuItem, InputLabel, Input, TextField, FormHelperText, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Content from '../../components/Content';

import Lottie from 'react-lottie';
import animationData from '../../assets/animations/success.json';
import animationDataError from '../../assets/animations/error.json';
import { Helmet } from 'react-helmet';
import InnerHTML from 'dangerously-set-html-content';
import Header from '../../components/Header';
import { ArrowForwardIos, Close } from '@material-ui/icons';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { format, isDate, parse, parseISO } from 'date-fns';
import InputMask from "react-input-mask";
import Recaptcha from 'react-recaptcha';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>



  createStyles({
    feedback: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
    },
    GridList: {
      margin: '28px 0 !important',
    },
    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",
    },
    Paper: {
      padding: '10px',
      marginBottom: '20px',
    },
    textMessage: {
      textAlign: 'center',
    },
    textMessageSub: {
      textAlign: 'center',
      color: theme.palette.text.disabled,
      marginBottom: '20px',
    },
    textMessageCustom: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    divCenter: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    formControl: {
      // margin: '48px 0',
      minWidth: 266,
      marginBottom: 15
    },
    buttonClick: {
      color: theme.palette.primary.main,
      fontSize: '1.2rem',
      padding: '0',
      textTransform: 'none',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: 50,
      marginLeft: -12,
    }
  })
);



type RouteParams = {
  id: string
}

interface IConviteItem {
  Codigo: string;
  Nome: string;
  Unidade: string;
  IdConvite: number;
  Unidades: IUnidadesConvite[];
  TextoIndiacaoSucesso: string;
  TextoIndicacao: string;
  IndicacaoDesabilitada: boolean;
}
interface IUnidadesConvite {
  Id: number;
  Nome: number;
}

interface ISignUpFormData {
  name: string,
  email: string,
  birth: string,
  cpf: string,
  gender: string,
  mobile: string,
  idUnidade: number,
  recaptcha: string | null,
}

type BookDetailProps = RouteComponentProps<RouteParams>;



const ConvitePage: React.FC<BookDetailProps> = ({ match }) => {
  const classes = useStyles();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [convite, setConvite] = useState<IConviteItem>();
  const [enviadoForm, setEnviadoForm] = useState<boolean>(false);
  const [agradecimento, setAgradecimento] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [unidadeSel, setUnidadeSel] = useState<IUnidadesConvite | undefined>();
  const [enviadoFormOk, setEnviadoFormOk] = useState<boolean>(false);
  const [isStopped, setIsStopped] = useState<boolean>(true);
  const { tenant, user } = useAuth();
  const { addToast } = useToast();
  const [openUnidade, setOpenUnidade] = React.useState(false);
  const [cpfInput, setCpfInput] = React.useState<string | undefined>();
  const [birthInput, setBirthInput] = React.useState<string | undefined>();
  const [mobileInput, setMobileInput] = React.useState<string | undefined>();
  const [selectedGender, setSelectedGender] = React.useState('Feminino')
  const refRecaptcha = useRef<Recaptcha | null>(null);
  const [message, setMessage] = useState('')
  const [hideUnidade, setHideUnidade] = useState<boolean>(true);


  function parseDateString(value: any, originalValue: string) {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "dd/MM/yyyy", new Date());

    return parsedDate;
  }

  function validadeCPF<bollean>(cpf: string | undefined | null) {

    //console.log(cpf)
    if (cpf) {
      cpf = cpf.replace('.', '',).replace('.', '',).replace('-', '');
      if (cpf == null) {
        return false;
      }
      if (cpf.length != 11) {
        return false;
      }
      if ((cpf == '00000000000') || (cpf == '11111111111') || (cpf == '22222222222') || (cpf == '33333333333') || (cpf == '44444444444') || (cpf == '55555555555') || (cpf == '66666666666') || (cpf == '77777777777') || (cpf == '88888888888') || (cpf == '99999999999')) {
        return false;
      }
      let numero: number = 0;
      let caracter: string = '';
      let numeros: string = '0123456789';
      let j: number = 10;
      let somatorio: number = 0;
      let resto: number = 0;
      let digito1: number = 0;
      let digito2: number = 0;
      let cpfAux: string = '';
      cpfAux = cpf.substring(0, 9);
      for (let i: number = 0; i < 9; i++) {
        caracter = cpfAux.charAt(i);
        if (numeros.search(caracter) == -1) {
          return false;
        }
        numero = Number(caracter);
        somatorio = somatorio + (numero * j);
        j--;
      }
      resto = somatorio % 11;
      digito1 = 11 - resto;
      if (digito1 > 9) {
        digito1 = 0;
      }
      j = 11;
      somatorio = 0;
      cpfAux = cpfAux + digito1;
      for (let i: number = 0; i < 10; i++) {
        caracter = cpfAux.charAt(i);
        numero = Number(caracter);
        somatorio = somatorio + (numero * j);
        j--;
      }
      resto = somatorio % 11;
      digito2 = 11 - resto;
      if (digito2 > 9) {
        digito2 = 0;
      }
      cpfAux = cpfAux + digito2;
      if (cpf != cpfAux) {
        return false;
      }
      else {
        return true;
      }
    } else { return false; }

  }

  let RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Nome obrigatório'),
    email: Yup.string().required('E-mail obrigatório').email("E-mail inválido"),
    birth: Yup.date().transform(parseDateString).required('Data de nascimento obrigatória').typeError('Data inválida'),
    gender: Yup.boolean().oneOf([true], "Escolha o gênero"),
    mobile: Yup.string(),
    cpf: Yup.string().test('cpf', 'CPF incorreto', (value) => (
      validadeCPF(value)
    )).required("CPF obrigatório"),
    idUnidade: Yup.string(),
    // password: Yup.string().required('Senha obrigatória'),
    // password_confirmation: Yup.string().oneOf(
    //   [Yup.ref('password'), undefined],'Senha diferente a primeira',
    //)
  });

  const { register, handleSubmit, errors, setValue, getValues } = useForm<ISignUpFormData>({
    resolver: yupResolver(RegisterSchema),
  });


  const recaptchaLoaded = useCallback(() => {
    console.log('recaptcha load')
  }, [])

  const recaptchaCallback = useCallback((response) => {
    sessionStorage.setItem('@HubfitPortal:recap', response);
    console.log(response)
  }, [])

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
  };
  const defaultOptionsError = {
    loop: false,
    autoplay: false,
    animationData: animationDataError,
  };

  const handleServiceClose = useCallback(() => {
    setOpenUnidade(false);
  }, [])

  const handleServiceOpen = useCallback(() => {
    setOpenUnidade(true);
  }, []);
  const handleChangeGender = ((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGender((event.target as HTMLInputElement).value);
  });

  const handleChangeUnidade = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    console.log(event)
    console.log(convite?.Unidades?.filter((item) => item.Id == event.target.value)[0])
    const unidadeNova = convite?.Unidades?.filter((item) => item.Id == event.target.value)[0];
    setUnidadeSel(unidadeNova);
    setOpenUnidade(false);
  }, [convite]);





  useEffect(() => {
    const idConvite = match.params.id;
    console.log("Convite", idConvite)
    if (idConvite && tenant) {
      setLoadingData(true);
      api.get('/invite', {
        params: {
          code: idConvite,
          idtenant: tenant?.idTenant,
        }
      }
      ).then(retorno => {
        console.log(retorno.data)
        setIsStopped(false);
        setLoadingData(false);
        const conv = retorno.data as IConviteItem;
        setConvite(conv);
        if (conv.Unidades.length == 1) {
          const unidadeNova = conv?.Unidades[0];
          console.log(unidadeNova)
          setUnidadeSel(unidadeNova);
          setHideUnidade(true)
        } else {
          setHideUnidade(false);
        }
      }).catch(err => {

        addToast({
          type: "error",
          title: 'Erro',
          description: 'Não foi possível se conectar ao servidor',
        });
      })
    }
  }, [tenant?.idTenant, match.params, setUnidadeSel]);

  const returnLp = useCallback(() => {
    window.location.reload();
  }, [])

  const onSubmitForm = useCallback(async (data: ISignUpFormData) => {
    try {
      // setLoadingSubmit(true)

      const formData = getValues();

      // console.log("Valores preenchidos pelo usuário:", formData);
      // console.log("Nome:", formData.name);
      // console.log("E-mail:", formData.email);
       console.log(formData.birth);
      // console.log("Celular:", formData.mobile);
      // console.log("Gênero:", selectedGender);
      // console.log("CPF:", formData.cpf);
      // console.log("Unidade:", unidadeSel?.Id);
      // console.log("Unidade:", convite?.IdConvite);
      // console.log("recaptcha", sessionStorage.getItem('@HubfitPortal:recap'))
      // console.log('idTenat', tenant.idTenant)
      // console.log('DEUUU CERTOOOO')

      api.post("/invite", {
        nome: formData.name,
        cpf: formData.cpf,
        nascimento: formData.birth,
        telefone: formData.mobile,
        email: formData.email,
        sexo: selectedGender,
        idUnidade: unidadeSel?.Id,
        idConvite: convite?.IdConvite,
        recaptcha: sessionStorage.getItem('@HubfitPortal:recap'),
        idTenant: tenant.idTenant
      }).then((response) => {
        console.log(response.data)
        setEnviadoForm(true);
        setEnviadoFormOk(true);
      }).catch((err) => {
        console.log(err.response.status)
        if (err.response.status == 400) {
          console.log(err.response.data.Message)
          setMessage(err.response.data.Message)
          setEnviadoForm(true);
          setEnviadoFormOk(false);
        } else {
          setMessage('Por favor, procure a recepção da unidade para mais informações.')
          setEnviadoForm(true);
          setEnviadoFormOk(false);
        }
      })

    } catch (err) {
      console.log(err)
      addToast({
        type: "error",
        title: 'Erro no cadastro',
        time: 20000,
        description: "Não foi possível realizar o cadastro"
      });

    } finally {
      setLoadingSubmit(false)
      sessionStorage.removeItem('@HubfitPortal:recap');
    }

  }, [tenant, selectedGender, unidadeSel, convite]);


  return (
    <>
      <Header isBack avatar hideSubmenu={!user} />
      <Helmet>
        <title>Convite - {tenant?.name}</title>
      </Helmet>
      {loadingData &&
        <Grid container direction="column"
          justify="center"
          alignItems="center" >
          <Grid item xs={12} className={classes.GridList}>
            <CircularProgress size={40} />
          </Grid>
        </Grid>
      }
      {!loadingData && enviadoFormOk == true && enviadoForm == true &&
        <div className={classes.mainRaised}>
          <Content>
            <Grid justify="center">
              <Grid item xs={12} sm={12} >
                <div>
                  <Lottie options={defaultOptions}
                    height={150}
                    width={150}
                    speed={0.6}
                    isStopped={isStopped}
                  />
                  <Grid container direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} sm={10} md={8} lg={6} >
                      <Paper elevation={1} className={classes.Paper} >
                        <Grid container alignItems="center" justify="center">
                          <Grid item xs={12} className={classes.textMessage}>
                            <Typography gutterBottom variant="h5" >
                              Cadastro realizado
                            </Typography>
                            <Typography gutterBottom variant="h5" >
                              {convite && convite?.TextoIndiacaoSucesso}
                            </Typography>
                          </Grid>
                          {/* <Grid item xs={12} className={classes.textMessageSub}>
                            <Typography gutterBottom variant="subtitle1" component="p" >
                              Enviamos em seu e-mail algumas informações!
                            </Typography>
                          </Grid> */}
                          {agradecimento &&
                            <Grid item xs={12} className={classes.textMessageCustom}>
                              {ReactHtmlParser(agradecimento)}
                            </Grid>
                          }
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      }

      {!loadingData && !enviadoFormOk && enviadoForm == true &&
        <div className={classes.mainRaised}>
          <Content>
            <Grid justify="center">
              <Grid item xs={12} sm={12} >
                <div>
                  <Lottie options={defaultOptionsError}
                    height={150}
                    width={150}
                    speed={0.6}
                    isStopped={isStopped}
                  />
                  <Grid container direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} sm={10} md={8} lg={6} >
                      <Paper elevation={1} className={classes.Paper} >
                        <Grid container alignItems="center" justify="center">
                          <Grid item xs={12} className={classes.textMessage}>
                            <Typography gutterBottom variant="h5" >
                              Cadastro não realizado!
                            </Typography>
                          </Grid>
                          {message &&
                            <Grid item xs={12} className={classes.textMessageCustom}>
                              {message}
                            </Grid>
                          }
                          {mensagem &&
                            <Grid item xs={12} className={classes.textMessageCustom}>
                              {ReactHtmlParser(mensagem)}
                            </Grid>
                          }
                          <Grid item xs={12} className={classes.textMessageCustom}>
                            <Button variant="contained" onClick={returnLp}>Voltar</Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Content>
        </div>
      }
      <div>
        {!loadingData && enviadoForm == false && convite &&

          <Container maxWidth="sm" style={{ marginTop: 30 }}>
            <Typography align="center" variant="h6" gutterBottom>Você acaba de receber um convite de {convite.Nome} para se cadastrar na {convite.Unidade}</Typography>
            {convite.TextoIndicacao &&
              <Typography align='center' variant='subtitle1' gutterBottom>{convite.TextoIndicacao}</Typography>
            }
            <Paper elevation={3} className={classes.Paper}>
              <Grid
                spacing={2}
                container
                direction="column"
                alignItems='center'
                style={{ padding: 15 }}
              >
                <form onSubmit={handleSubmit(onSubmitForm)} >
                  <Grid item xs={12} direction="column" >
                    {!hideUnidade &&
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel id="servico">Qual unidade?</InputLabel>
                        <Select
                          labelId="unidade"
                          id="unidade"
                          open={openUnidade}
                          onClose={handleServiceClose}
                          onOpen={handleServiceOpen}
                          value={unidadeSel?.Id}
                          onChange={handleChangeUnidade}
                        >
                          {convite.Unidades?.map((item) => (
                            <MenuItem key={item.Id} value={item.Id} >{item.Nome}</MenuItem>
                          ))}

                        </Select>
                      </FormControl>
                    }
                    {unidadeSel &&
                      <>
                        <Grid  >
                          <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl} error={!!errors.name?.message} >
                              <TextField
                                variant="standard"
                                required
                                inputRef={register}
                                name="name"
                                id='name'
                                label="Nome completo"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl} error={!!errors.cpf?.message}>
                              <InputMask

                                mask="999.999.999-99"
                                disabled={false}
                                maskChar=""
                                value={cpfInput}
                                maskPlaceholder={null}
                              >
                                {<TextField variant="standard" label="CPF" name="cpf" id="cpf" inputRef={register} autoComplete="off" />}
                              </InputMask>
                              <FormHelperText>{errors.cpf?.message}</FormHelperText>

                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl} error={!!errors.email?.message}>
                              <TextField
                                variant="standard"
                                type='email'
                                required
                                inputRef={register}
                                name="email"
                                id='email'
                                label="E-mail"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl} error={!!errors.mobile?.message}>
                              <InputMask
                                mask="(99) 99999-9999"
                                maskChar=""
                                disabled={false}
                                value={mobileInput}
                                maskPlaceholder={null}
                              >
                                <TextField
                                  variant="standard"
                                  type='tel'
                                  required
                                  inputRef={register}
                                  name="mobile"
                                  id="mobile"
                                  label="Celular"
                                />
                                </InputMask>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl} error={!!errors.birth?.message}>
                              <InputMask
                                mask="99/99/9999"
                                disabled={false}
                                maskChar=""
                                value={birthInput}
                                maskPlaceholder={null}
                              >
                                {<TextField
                                  type='tel'
                                  variant="standard"
                                  label="Nascimento"
                                  name="birth"
                                  id="birth"
                                  inputRef={register} autoComplete="off" />}
                              </InputMask>
                              <FormHelperText>{errors.birth?.message}</FormHelperText>

                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl component="fieldset" className={classes.formControl} error={!!errors.gender?.message}>
                              <FormLabel component="legend">Gênero</FormLabel>
                              <RadioGroup
                                row
                                aria-label="gender"
                                name="gender"
                                id='gender'
                                ref={register}
                                value={selectedGender}
                                onChange={handleChangeGender}>
                                <FormControlLabel value="Feminino" control={<Radio color="primary" />} label="Feminino" />
                                <FormControlLabel value="Masculino" control={<Radio color="primary" />} label="Masculino" />
                              </RadioGroup>
                              <FormHelperText>{errors.gender?.message}</FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Content>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            <Recaptcha
                              ref={refRecaptcha}
                              hl='pt-BR'
                              size='normal'
                              sitekey="6LfHOKIUAAAAAPwaZ0c_LxLmhZV4wckS4xGKr9Nr"
                              render="explicit"
                              onloadCallback={recaptchaLoaded}
                              verifyCallback={recaptchaCallback}
                            />
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{ marginTop: 25 }}
                          >
                            <Button
                              disabled={loadingSubmit}
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="large"
                              endIcon={<ArrowForwardIos />}
                            >
                              Aceitar convite
                              {loadingSubmit && (
                                <CircularProgress size={24} style={{ marginLeft: "10px" }} />
                              )}
                            </Button>
                          </Grid>

                        </Content>
                      </>
                    }
                  </Grid>
                </form>
              </Grid>
            </Paper>


          </Container>
        }
      </div>



    </>
  );

}
export default withRouter(ConvitePage);
